"use client";

import { useContext } from "react";

import { UserContext } from "@/utils/UserContext";

export const HeaderDefaultName = () => {
  const { user } = useContext(UserContext);
  return user.first || user.last ? `${user?.first} ${user?.last}` : user.phone;
};
